export default [
  {
    path: '/internal',
    name: 'internal',
    component: () => import(/* webpackChunkName: "internal" */ '@/view/internal/index.vue'),
    children: [
      {
        path: 'loading-mingguan',
        name: 'loading-mingguan',
        component: () =>
          import(/* webpackChunkName: "loading-mingguan" */ '@/view/internal/loading-mingguan/index.vue'),
      },
      {
        path: 'loading-mingguan-add',
        name: 'loading-mingguan-add',
        component: () =>
          import(/* webpackChunkName: "loading-mingguan-add" */ '@/view/internal/loading-mingguan/add.vue'),
      },
      {
        path: 'loading-mingguan-edit/:id',
        name: 'loading-mingguan-edit',
        component: () =>
          import(/* webpackChunkName: "loading-mingguan-edit" */ '@/view/internal/loading-mingguan/edit.vue'),
      },
      {
        path: 'konfirmasi-rakor-pltu',
        name: 'konfirmasi-rakor-pltu',
        component: () =>
          import(/* webpackChunkName: "konfirmasi-rakor-pltu" */ '@/view/internal/konfirmasi-rakor-pltu/index.vue'),
      },
      {
        path: 'konfirmasi-rakor-pltu-add',
        name: 'konfirmasi-rakor-pltu-add',
        component: () =>
          import(/* webpackChunkName: "konfirmasi-rakor-pltu-add" */ '@/view/internal/konfirmasi-rakor-pltu/add.vue'),
      },
      {
        path: 'konfirmasi-rakor-pltu-edit/:id',
        name: 'konfirmasi-rakor-pltu-edit',
        component: () =>
          import(/* webpackChunkName: "konfirmasi-rakor-pltu-edit" */ '@/view/internal/konfirmasi-rakor-pltu/edit.vue'),
      },
      {
        path: 'kumulatif-target-pasokan',
        name: 'kumulatif-target-pasokan',
        component: () =>
          import(
            /* webpackChunkName: "kumulatif-target-pasokan" */ '@/view/internal/kumulatif-target-pasokan/index.vue'
          ),
      },
      {
        path: 'kumulatif-target-pasokan-add',
        name: 'kumulatif-target-pasokan-add',
        component: () =>
          import(
            /* webpackChunkName: "kumulatif-target-pasokan-add" */ '@/view/internal/kumulatif-target-pasokan/add.vue'
          ),
      },
      {
        path: 'kumulatif-target-pasokan-edit/:id',
        name: 'kumulatif-target-pasokan-edit',
        component: () =>
          import(
            /* webpackChunkName: "kumulatif-target-pasokan-edit" */ '@/view/internal/kumulatif-target-pasokan/edit.vue'
          ),
      },
      {
        path: 'rekap-pasokan-mitra',
        name: 'rekap-pasokan-mitra',
        component: () =>
          import(/* webpackChunkName: "rekap-pasokan-mitra" */ '@/view/internal/rekap-pasokan-mitra/index.vue'),
      },
      {
        path: 'rekap-pasokan-mitra-add',
        name: 'rekap-pasokan-mitra-add',
        component: () =>
          import(/* webpackChunkName: "rekap-pasokan-mitra-add" */ '@/view/internal/rekap-pasokan-mitra/add.vue'),
      },
      {
        path: 'rekap-pasokan-mitra-edit/:id',
        name: 'rekap-pasokan-mitra-edit',
        component: () =>
          import(/* webpackChunkName: "rekap-pasokan-mitra-edit" */ '@/view/internal/rekap-pasokan-mitra/edit.vue'),
      },
      {
        path: 'realisasi-volume-pasokan',
        name: 'realisasi-volume-pasokan',
        component: () =>
          import(
            /* webpackChunkName: "realisasi-volume-pasokan" */ '@/view/internal/realisasi-volume-pasokan/index.vue'
          ),
      },
      {
        path: 'realisasi-volume-pasokan-add',
        name: 'realisasi-volume-pasokan-add',
        component: () =>
          import(
            /* webpackChunkName: "realisasi-volume-pasokan-add" */ '@/view/internal/realisasi-volume-pasokan/add.vue'
          ),
      },
      {
        path: 'realisasi-volume-pasokan-edit/:id',
        name: 'realisasi-volume-pasokan-edit',
        component: () =>
          import(
            /* webpackChunkName: "realisasi-volume-pasokan-edit" */ '@/view/internal/realisasi-volume-pasokan/edit.vue'
          ),
      },
    ],
  },
];
