export default [
  {
    path: '/eksternal',
    name: 'eksternal',
    component: () => import(/* webpackChunkName: "eksternal" */ '@/view/eksternal/index.vue'),
    children: [
      {
        path: 'kepemilikan-saham',
        name: 'kepemilikan-saham',
        component: () =>
          import(/* webpackChunkName: "kepemilikan-saham" */ '@/view/eksternal/kepemilikan-saham/index.vue'),
      },
      {
        path: 'kepemilikan-saham-add',
        name: 'kepemilikan-saham-add',
        component: () =>
          import(/* webpackChunkName: "kepemilikan-saham-add" */ '@/view/eksternal/kepemilikan-saham/add.vue'),
      },
      {
        path: 'kepemilikan-saham-edit/:id',
        name: 'kepemilikan-saham-edit',
        component: () =>
          import(/* webpackChunkName: "kepemilikan-saham-edit" */ '@/view/eksternal/kepemilikan-saham/edit.vue'),
      },
      {
        path: 'penyertaan-modal',
        name: 'penyertaan-modal',
        component: () =>
          import(/* webpackChunkName: "penyertaan-modal" */ '@/view/eksternal/penyertaan-modal/index.vue'),
      },
      {
        path: 'penyertaan-modal-add',
        name: 'penyertaan-modal-add',
        component: () =>
          import(/* webpackChunkName: "penyertaan-modal-add" */ '@/view/eksternal/penyertaan-modal/add.vue'),
      },
      {
        path: 'penyertaan-modal-edit/:id',
        name: 'penyertaan-modal-edit',
        component: () =>
          import(/* webpackChunkName: "penyertaan-modal-edit" */ '@/view/eksternal/penyertaan-modal/edit.vue'),
      },
      {
        path: 'notulen',
        name: 'notulen',
        component: () => import(/* webpackChunkName: "notulen" */ '@/view/eksternal/notulen/index.vue'),
      },
      {
        path: 'notulen-add',
        name: 'notulen-add',
        component: () => import(/* webpackChunkName: "notulen-add" */ '@/view/eksternal/notulen/add.vue'),
      },
      {
        path: 'notulen-edit/:id',
        name: 'notulen-edit',
        component: () => import(/* webpackChunkName: "notulen-edit" */ '@/view/eksternal/notulen/edit.vue'),
      },
      {
        path: 'pengawasan-dekom',
        name: 'pengawasan-dekom',
        component: () =>
          import(/* webpackChunkName: "pengawasan-dekom" */ '@/view/eksternal/pengawasan-dekom/index.vue'),
      },
      {
        path: 'pengawasan-dekom-add',
        name: 'pengawasan-dekom-add',
        component: () =>
          import(/* webpackChunkName: "pengawasan-dekom-add" */ '@/view/eksternal/pengawasan-dekom/add.vue'),
      },
      {
        path: 'pengawasan-dekom-edit/:id',
        name: 'pengawasan-dekom-edit',
        component: () =>
          import(/* webpackChunkName: "pengawasan-dekom-edit" */ '@/view/eksternal/pengawasan-dekom/edit.vue'),
      },
      {
        path: 'kpi-direksi-dekom',
        name: 'kpi-direksi-dekom',
        component: () =>
          import(/* webpackChunkName: "kpi-direksi-dekom" */ '@/view/eksternal/kpi-direksi-dekom/index.vue'),
      },
      {
        path: 'kpi-direksi-dekom-add',
        name: 'kpi-direksi-dekom-add',
        component: () =>
          import(/* webpackChunkName: "kpi-direksi-dekom-add" */ '@/view/eksternal/kpi-direksi-dekom/add.vue'),
      },
      {
        path: 'kpi-direksi-dekom-edit/:id',
        name: 'kpi-direksi-dekom-edit',
        component: () =>
          import(/* webpackChunkName: "kpi-direksi-dekom-edit" */ '@/view/eksternal/kpi-direksi-dekom/edit.vue'),
      },
      {
        path: 'akta-risalah',
        name: 'akta-risalah',
        component: () => import(/* webpackChunkName: "akta-risalah" */ '@/view/eksternal/akta-risalah/index.vue'),
      },
      {
        path: 'akta-risalah-add',
        name: 'akta-risalah-add',
        component: () => import(/* webpackChunkName: "akta-risalah-add" */ '@/view/eksternal/akta-risalah/add.vue'),
      },
      {
        path: 'akta-risalah-edit/:id',
        name: 'akta-risalah-edit',
        component: () => import(/* webpackChunkName: "akta-risalah-edit" */ '@/view/eksternal/akta-risalah/edit.vue'),
      },
      {
        path: 'daftar-hadir-radir',
        name: 'daftar-hadir-radir',
        component: () =>
          import(/* webpackChunkName: "daftar-hadir-radir" */ '@/view/eksternal/daftar-hadir-radir/index.vue'),
      },
      {
        path: 'daftar-hadir-radir-add',
        name: 'daftar-hadir-radir-add',
        component: () =>
          import(/* webpackChunkName: "daftar-hadir-radir-add" */ '@/view/eksternal/daftar-hadir-radir/add.vue'),
      },
      {
        path: 'daftar-hadir-radir-edit/:id',
        name: 'daftar-hadir-radir-edit',
        component: () =>
          import(/* webpackChunkName: "daftar-hadir-radir-edit" */ '@/view/eksternal/daftar-hadir-radir/edit.vue'),
      },
      {
        path: 'bisnis-model-plnbb',
        name: 'bisnis-model-plnbb',
        component: () =>
          import(/* webpackChunkName: "bisnis-model-plnbb" */ '@/view/eksternal/bisnis-model-plnbb/index.vue'),
      },
      {
        path: 'bisnis-model-plnbb-add',
        name: 'bisnis-model-plnbb-add',
        component: () =>
          import(/* webpackChunkName: "bisnis-model-plnbb-add" */ '@/view/eksternal/bisnis-model-plnbb/add.vue'),
      },
      {
        path: 'bisnis-model-plnbb-edit/:id',
        name: 'bisnis-model-plnbb-edit',
        component: () =>
          import(/* webpackChunkName: "bisnis-model-plnbb-edit" */ '@/view/eksternal/bisnis-model-plnbb/edit.vue'),
      },
      {
        path: 'laporan-manajemen-eksternal',
        name: 'laporan-manajemen-eksternal',
        component: () =>
          import(
            /* webpackChunkName: "laporan-manajemen-eksternal" */ '@/view/eksternal/laporan-manajemen-eksternal/index.vue'
          ),
      },
      {
        path: 'laporan-manajemen-eksternal-add',
        name: 'laporan-manajemen-eksternal-add',
        component: () =>
          import(
            /* webpackChunkName: "laporan-manajemen-eksternal-add" */ '@/view/eksternal/laporan-manajemen-eksternal/add.vue'
          ),
      },
      {
        path: 'laporan-manajemen-eksternal-edit/:id',
        name: 'laporan-manajemen-eksternal-edit',
        component: () =>
          import(
            /* webpackChunkName: "laporan-manajemen-eksternal-edit" */ '@/view/eksternal/laporan-manajemen-eksternal/edit.vue'
          ),
      },
    ],
  },
];
