import mutations from './mutations';
import Cookies from 'js-cookie';
import api from '@/api';
import { setItem, removeItem } from '@/utility/localStorageControl';
import { notification } from 'ant-design-vue';

const addNotificationSuccess = message => {
  notification.success({
    message: message,
  });
};

const addNotificationError = err => {
  notification.error({
    message: err,
  });
};

const state = () => ({
  login: Cookies.get('logedIn'),
  loading: false,
  error: null,
  data: {},
});

const actions = {
  async login({ commit }, payload) {
    try {
      commit('loginBegin');

      let postData = {
        email: payload.email,
        password: payload.password,
      };

      api
        .post('login', postData, { headers: { 'Content-Type': 'application/json' } })
        .then(response => {
          if (response.data.profile.is_first_login) {
            commit('loginSuccessFirst', postData);
            addNotificationSuccess('Login Pertama Kali Berhasil');
          } else if (response.data.is_password_expired) {
            commit('loginSuccessExpiredPassword', postData);
            addNotificationSuccess('Password Anda Sudah Kadaluarsa, Silahkan Buat Password Baru.');
          } else {
            setItem('access_token', response.data.access_token);
            setItem('profile_plnbb', response.data.profile);
            setItem('permissions', response.data.permissions);
            Cookies.set('logedIn', true);
            commit('loginSuccess', true);
            addNotificationSuccess('Login Berhasil');
          }
        })
        .catch(err => {
          commit('loginErr', err);
          addNotificationError(err.response.data.message);
        });
    } catch (err) {
      commit('loginErr', err);
      addNotificationError(err.message);
    }
  },
  async logOut({ commit }) {
    try {
      commit('logoutBegin');
      Cookies.remove('logedIn');
      commit('logoutSuccess', null);
      removeItem('profile_plnbb');
      removeItem('access_token');
    } catch (err) {
      commit('logoutErr', err);
    }
  },
  async createPassword({ commit }, payload) {
    try {
      commit('loginBegin');

      let postData = {
        email: payload.email,
        password: payload.password,
        newPassword: payload.newPassword,
      };

      api
        .post('create-password', postData, { headers: { 'Content-Type': 'application/json' } })
        .then(response => {
          setItem('access_token', response.data.access_token);
          setItem('profile_plnbb', response.data.profile);
          setItem('permissions', response.data.permissions);
          Cookies.set('logedIn', true);
          commit('loginSuccess', true);
          addNotificationSuccess('Login Berhasil');
        })
        .catch(err => {
          commit('loginErr', err);
          addNotificationError(err.response.data.message);
        });
    } catch (err) {
      commit('loginErr', err);
      addNotificationError(err.message);
    }
  },
  async resetPassword({ commit }, payload) {
    try {
      commit('loginBegin');

      let postData = {
        email: payload.email,
      };

      api
        .post('reset-password', postData, { headers: { 'Content-Type': 'application/json' } })
        .then(response => {
          commit('resetPasswordSuccess', true);
          addNotificationSuccess(response.data.message);
        })
        .catch(err => {
          commit('loginErr', err);
          addNotificationError(err.response.data.message);
        });
    } catch (err) {
      commit('loginErr', err);
      addNotificationError(err.message);
    }
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
