export default [
  {
    path: '/laporan-manajemen',
    name: 'laporan-manajemen',
    component: () => import(/* webpackChunkName: "laporan-manajemen" */ '@/view/laporan-manajemen/index.vue'),
    children: [
      {
        path: 'laporan-manajemen/:id',
        name: 'sub-laporan-manajemen',
        component: () =>
          import(/* webpackChunkName: "laporan-manajemen" */ '@/view/laporan-manajemen/laporan-manajemen/index.vue'),
      },
      {
        path: 'laporan-manajemen-add/:id',
        name: 'sub-laporan-manajemen-add',
        component: () =>
          import(/* webpackChunkName: "laporan-manajemen-add" */ '@/view/laporan-manajemen/laporan-manajemen/add.vue'),
      },
      {
        path: 'laporan-manajemen-edit/:id/:row_id/:month/:year',
        name: 'sub-laporan-manajemen-edit',
        component: () =>
          import(
            /* webpackChunkName: "laporan-manajemen-edit" */ '@/view/laporan-manajemen/laporan-manajemen/edit.vue'
          ),
      },
      {
        path: 'laporan-manajemen-target-realisasi/:id/index',
        name: 'sub-laporan-manajemen-target-realisasi',
        component: () =>
          import(
            /* webpackChunkName: "laporan-manajemen-edit" */ '@/view/laporan-manajemen/target-realisasi/index.vue'
          ),
      },
      {
        path: 'laporan-manajemen-target-realisasi-add/:id',
        name: 'sub-laporan-manajemen-target-realisasi-add',
        component: () =>
          import(/* webpackChunkName: "laporan-manajemen-edit" */ '@/view/laporan-manajemen/target-realisasi/add.vue'),
      },
      {
        path: 'laporan-manajemen-target-realisasi-edit/:id/:itemId',
        name: 'sub-laporan-manajemen-target-realisasi-edit',
        component: () =>
          import(/* webpackChunkName: "laporan-manajemen-edit" */ '@/view/laporan-manajemen/target-realisasi/edit.vue'),
      },
      {
        path: 'laporan-manajemen-target-realisasi-triwulan/:id',
        name: 'sub-laporan-manajemen-target-realisasi-triwulan',
        component: () =>
          import(
            /* webpackChunkName: "laporan-manajemen-edit" */ '@/view/laporan-manajemen/target-realisasi/triwulan.vue'
          ),
      },
      {
        path: 'kebutuhan-tk-divisi-manajemen/index',
        name: 'kebutuhan-tk-divisi-manajemen-index',
        component: () => import('@/view/laporan-manajemen/divisi-manajemen/index.vue'),
      },
      {
        path: 'kebutuhan-tk-divisi-manajemen/add',
        name: 'kebutuhan-tk-divisi-manajemen-add',
        component: () => import('@/view/laporan-manajemen/divisi-manajemen/add.vue'),
      },
      {
        path: 'kebutuhan-tk-divisi-manajemen/:itemId',
        name: 'kebutuhan-tk-divisi-manajemen-edit',
        component: () => import('@/view/laporan-manajemen/divisi-manajemen/edit.vue'),
      },
      {
        path: 'sharing-knowledge',
        name: 'sharing-knowledge-index',
        component: () => import('@/view/laporan-manajemen/sharing-knowledge/index.vue'),
      },
      {
        path: 'sharing-knowledge/add',
        name: 'sharing-knowledge-add',
        component: () => import('@/view/laporan-manajemen/sharing-knowledge/add.vue'),
      },
      {
        path: 'sharing-knowledge/:itemId',
        name: 'sharing-knowledge-edit',
        component: () => import('@/view/laporan-manajemen/sharing-knowledge/edit.vue'),
      },
      {
        path: 'kegiatan-crs',
        name: 'kegiatan-crs-index',
        component: () => import('@/view/laporan-manajemen/kegiatan-crs/index.vue'),
      },
      {
        path: 'kegiatan-crs/add',
        name: 'kegiatan-crs-add',
        component: () => import('@/view/laporan-manajemen/kegiatan-crs/add.vue'),
      },
      {
        path: 'kegiatan-crs/:itemId',
        name: 'kegiatan-crs-edit',
        component: () => import('@/view/laporan-manajemen/kegiatan-crs/edit.vue'),
      },
      {
        path: 'progress-rjpp',
        name: 'progress-rjpp-index',
        component: () => import('@/view/laporan-manajemen/progress-rjpp/index.vue'),
      },
      {
        path: 'progress-rjpp/add',
        name: 'progress-rjpp-add',
        component: () => import('@/view/laporan-manajemen/progress-rjpp/add.vue'),
      },
      {
        path: 'progress-rjpp-edit/:itemId',
        name: 'progress-rjpp-edit',
        component: () => import('@/view/laporan-manajemen/progress-rjpp/edit.vue'),
      },
      {
        path: 'progress-rjpp-uraian-edit/:itemId',
        name: 'progress-rjpp-uraian-edit',
        component: () => import('@/view/laporan-manajemen/progress-rjpp/uraian/edit.vue'),
      },
      {
        path: 'progress-rjpp-progress-edit/:itemId',
        name: 'progress-rjpp-progress-edit',
        component: () => import('@/view/laporan-manajemen/progress-rjpp/progress/edit.vue'),
      },
    ],
  },
];
