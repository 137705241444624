export default [
  // redirect from / to /dash
  {
    path: '/',
    redirect: '/dash/kpi-korporat',
  },
  {
    path: '/dash',
    name: 'dash',
    component: () => import(/* webpackChunkName: "dash" */ '@/view/dash/index.vue'),
    children: [
      {
        path: 'kpi-korporat',
        name: 'dash-kpi-korporat',
        component: () => import(/* webpackChunkName: "kpi-korporat" */ '@/view/dash/kpi-korporat.vue'),
      },
      {
        path: 'kpi-divisi/:id',
        name: 'dash-kpi-divisi',
        component: () => import(/* webpackChunkName: "kpi-divisi" */ '@/view/dash/kpi-divisi.vue'),
      },
    ],
  },
];
