import axios from 'axios';

const axiosApi = axios.create({
  baseURL: process.env.VUE_APP_API_ENDPOINT,
});

axiosApi.interceptors.request.use(
  config => {
    let token = localStorage.getItem('plnbb_token');

    if (token) {
      config.headers['Authorization'] = 'Bearer ' + JSON.parse(token);
    }

    return config;
  },

  error => {
    return Promise.reject(error);
  },
);

export default axiosApi;
