export default [
  {
    path: '/kpi',
    name: 'kpi',
    component: () => import(/* webpackChunkName: "kpi" */ '@/view/kpi/index.vue'),
    children: [
      {
        path: 'kpi-korporat',
        redirect: () => {
          const currentDate = new Date();
          const year = currentDate.getFullYear();
          const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
          return `/kpi/kpi-korporat/${month}/${year}`;
        },
      },
      {
        path: 'kpi-korporat/:month/:year',
        name: 'kpi-korporat',
        component: () => import(/* webpackChunkName: "kpi-korporat" */ '@/view/kpi/kpi-korporat/index.vue'),
      },
      {
        path: 'kpi-korporat-add/:month/:year',
        name: 'kpi-korporat-add',
        component: () => import(/* webpackChunkName: "kpi-korporat-add" */ '@/view/kpi/kpi-korporat/add.vue'),
      },
      {
        path: 'kpi-korporat-edit/:id/:month/:year',
        name: 'kpi-korporat-edit',
        component: () => import(/* webpackChunkName: "kpi-korporat-edit" */ '@/view/kpi/kpi-korporat/edit.vue'),
      },
      {
        path: 'kpi-korporat-add-kpi/:id/:month/:year',
        name: 'kpi-korporat-add-kpi',
        component: () => import(/* webpackChunkName: "kpi-korporat-add-kpi" */ '@/view/kpi/kpi-korporat/add-kpi.vue'),
      },
      {
        path: 'kpi-korporat-upload-file/:id/:month/:year',
        name: 'kpi-korporat-upload-file',
        component: () =>
          import(/* webpackChunkName: "kpi-korporat-upload-file" */ '@/view/kpi/kpi-korporat/upload-file.vue'),
      },
      {
        path: 'kpi-korporat-edit-kpi/:id/:month/:year',
        name: 'kpi-korporat-edit-kpi',
        component: () => import(/* webpackChunkName: "kpi-korporat-edit-kpi" */ '@/view/kpi/kpi-korporat/edit-kpi.vue'),
      },
      {
        path: 'kpi-korporat-add-target/:id/:month/:year',
        name: 'kpi-korporat-add-target',
        component: () =>
          import(/* webpackChunkName: "kpi-korporat-add-target" */ '@/view/kpi/kpi-korporat/add-target.vue'),
      },
      {
        path: 'kpi-korporat-detail-target/:id/:month/:year',
        name: 'kpi-korporat-detail-target',
        component: () =>
          import(/* webpackChunkName: "kpi-korporat-detail-target" */ '@/view/kpi/kpi-korporat/detail-target.vue'),
      },
      {
        path: 'kpi-korporat-edit-target/:id',
        name: 'kpi-korporat-edit-target',
        component: () =>
          import(/* webpackChunkName: "kpi-korporat-edit-target" */ '@/view/kpi/kpi-korporat/edit-target.vue'),
      },
      {
        path: 'kpi-korporat-add-realisasi/:id/:month/:year',
        name: 'kpi-korporat-add-realisasi',
        component: () =>
          import(/* webpackChunkName: "kpi-korporat-add-realisasi" */ '@/view/kpi/kpi-korporat/add-realisasi.vue'),
      },
      {
        path: 'kpi-korporat-detail-realisasi/:id/:month/:year',
        name: 'kpi-korporat-detail-realisasi',
        component: () =>
          import(
            /* webpackChunkName: "kpi-korporat-detail-realisasi" */ '@/view/kpi/kpi-korporat/detail-realisasi.vue'
          ),
      },
      {
        path: 'kpi-korporat-edit-realisasi/:id',
        name: 'kpi-korporat-edit-realisasi',
        component: () =>
          import(/* webpackChunkName: "kpi-korporat-edit-realisasi" */ '@/view/kpi/kpi-korporat/edit-realisasi.vue'),
      },
      {
        path: 'target-kpi-korporat',
        name: 'target-kpi-korporat',
        component: () =>
          import(/* webpackChunkName: "target-kpi-korporat" */ '@/view/kpi/target-kpi-korporat/index.vue'),
      },
      {
        path: 'target-kpi-korporat-add',
        name: 'target-kpi-korporat-add',
        component: () =>
          import(/* webpackChunkName: "target-kpi-korporat-add" */ '@/view/kpi/target-kpi-korporat/add.vue'),
      },
      {
        path: 'target-kpi-korporat-edit/:id',
        name: 'target-kpi-korporat-edit',
        component: () =>
          import(/* webpackChunkName: "target-kpi-korporat-edit" */ '@/view/kpi/target-kpi-korporat/edit.vue'),
      },
      {
        path: 'laporan-kpi-korporat',
        name: 'laporan-kpi-korporat',
        component: () =>
          import(/* webpackChunkName: "laporan-kpi-korporat" */ '@/view/kpi/laporan-kpi-korporat/index.vue'),
      },
      {
        path: 'laporan-kpi-korporat-add',
        name: 'laporan-kpi-korporat-add',
        component: () =>
          import(/* webpackChunkName: "laporan-kpi-korporat-add" */ '@/view/kpi/laporan-kpi-korporat/add.vue'),
      },
      {
        path: 'laporan-kpi-korporat-edit/:id',
        name: 'laporan-kpi-korporat-edit',
        component: () =>
          import(/* webpackChunkName: "laporan-kpi-korporat-edit" */ '@/view/kpi/laporan-kpi-korporat/edit.vue'),
      },
      {
        path: 'kpi-divisi/:divisi_id',
        redirect: to => {
          const { divisi_id } = to.params;
          const currentDate = new Date();
          const year = currentDate.getFullYear();
          const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
          return `/kpi/kpi-divisi/${divisi_id}/${month}/${year}`;
        },
      },
      {
        path: 'kpi-divisi/:divisi_id/:month/:year',
        name: 'kpi-divisi',
        component: () => import(/* webpackChunkName: "kpi-divisi" */ '@/view/kpi/kpi-divisi/index.vue'),
      },
      {
        path: 'kpi-divisi-add/:divisi_id/:month/:year',
        name: 'kpi-divisi-add',
        component: () => import(/* webpackChunkName: "kpi-divisi-add" */ '@/view/kpi/kpi-divisi/add.vue'),
      },
      {
        path: 'kpi-divisi-edit/:id/:divisi_id/:month/:year',
        name: 'kpi-divisi-edit',
        component: () => import(/* webpackChunkName: "kpi-divisi-edit" */ '@/view/kpi/kpi-divisi/edit.vue'),
      },
      {
        path: 'kpi-divisi-add-kpi/:id/:divisi_id/:month/:year',
        name: 'kpi-divisi-add-kpi',
        component: () => import(/* webpackChunkName: "kpi-divisi-add-kpi" */ '@/view/kpi/kpi-divisi/add-kpi.vue'),
      },
      {
        path: 'kpi-divisi-upload-file/:divisi_id/:id/:month/:year',
        name: 'kpi-divisi-upload-file',
        component: () =>
          import(/* webpackChunkName: "kpi-divisi-upload-file" */ '@/view/kpi/kpi-divisi/upload-file.vue'),
      },
      {
        path: 'kpi-divisi-edit-kpi/:id/:divisi_id/:month/:year',
        name: 'kpi-divisi-edit-kpi',
        component: () => import(/* webpackChunkName: "kpi-divisi-edit-kpi" */ '@/view/kpi/kpi-divisi/edit-kpi.vue'),
      },
      {
        path: 'kpi-divisi-add-target/:id/:month/:year/:divisi_id',
        name: 'kpi-divisi-add-target',
        component: () => import(/* webpackChunkName: "kpi-divisi-add-target" */ '@/view/kpi/kpi-divisi/add-target.vue'),
      },
      {
        path: 'kpi-divisi-detail-target/:id/:month/:year/:divisi_id',
        name: 'kpi-divisi-detail-target',
        component: () =>
          import(/* webpackChunkName: "kpi-divisi-detail-target" */ '@/view/kpi/kpi-divisi/detail-target.vue'),
      },
      {
        path: 'kpi-divisi-edit-target/:id/:month/:year/:divisi_id',
        name: 'kpi-divisi-edit-target',
        component: () =>
          import(/* webpackChunkName: "kpi-divisi-edit-target" */ '@/view/kpi/kpi-divisi/edit-target.vue'),
      },
      {
        path: 'kpi-divisi-add-realisasi/:id/:month/:year/:divisi_id',
        name: 'kpi-divisi-add-realisasi',
        component: () =>
          import(/* webpackChunkName: "kpi-divisi-add-realisasi" */ '@/view/kpi/kpi-divisi/add-realisasi.vue'),
      },
      {
        path: 'kpi-divisi-detail-realisasi/:id/:month/:year/:divisi_id',
        name: 'kpi-divisi-detail-realisasi',
        component: () =>
          import(/* webpackChunkName: "kpi-divisi-detail-realisasi" */ '@/view/kpi/kpi-divisi/detail-realisasi.vue'),
      },
      {
        path: 'kpi-divisi-edit-realisasi/:id/:month/:year/:divisi_id',
        name: 'kpi-divisi-edit-realisasi',
        component: () =>
          import(/* webpackChunkName: "kpi-divisi-edit-realisasi" */ '@/view/kpi/kpi-divisi/edit-realisasi.vue'),
      },
      {
        path: 'target-kpi-divisi',
        name: 'target-kpi-divisi',
        component: () => import(/* webpackChunkName: "target-kpi-divisi" */ '@/view/kpi/target-kpi-divisi/index.vue'),
      },
      {
        path: 'target-kpi-divisi-add',
        name: 'target-kpi-divisi-add',
        component: () => import(/* webpackChunkName: "target-kpi-divisi-add" */ '@/view/kpi/target-kpi-divisi/add.vue'),
      },
      {
        path: 'target-kpi-divisi-edit/:id',
        name: 'target-kpi-divisi-edit',
        component: () =>
          import(/* webpackChunkName: "target-kpi-divisi-edit" */ '@/view/kpi/target-kpi-divisi/edit.vue'),
      },
      {
        path: 'laporan-kpi-divisi',
        name: 'laporan-kpi-divisi',
        component: () => import(/* webpackChunkName: "laporan-kpi-divisi" */ '@/view/kpi/laporan-kpi-divisi/index.vue'),
      },
      {
        path: 'laporan-kpi-divisi-add',
        name: 'laporan-kpi-divisi-add',
        component: () =>
          import(/* webpackChunkName: "laporan-kpi-divisi-add" */ '@/view/kpi/laporan-kpi-divisi/add.vue'),
      },
      {
        path: 'laporan-kpi-divisi-edit/:id',
        name: 'laporan-kpi-divisi-edit',
        component: () =>
          import(/* webpackChunkName: "laporan-kpi-divisi-edit" */ '@/view/kpi/laporan-kpi-divisi/edit.vue'),
      },
      {
        path: 'kpi-korporat-table/:id/:month/:year',
        name: 'kpi-korporat-table',
        component: () => import(/* webpackChunkName: "kpi-korporat-table" */ '@/view/kpi/kpi-korporat-table/index.vue'),
      },
      {
        path: 'kpi-divisi-table/:id/:divisi_id/:month/:year',
        name: 'kpi-divisi-table',
        component: () => import(/* webpackChunkName: "kpi-divisi-table" */ '@/view/kpi/kpi-divisi-table/index.vue'),
      },
    ],
  },
];
